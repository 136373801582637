






























import { mapGetters } from 'vuex'

export default {
  name: "Smarthubs",
  computed: {
    ...mapGetters('sensors', ['smarthubs']),
    ...mapGetters('users', ['isAdmin']),
  },
  methods: {
    showIpLink(sh: any): boolean {
      return this.isAdmin && sh.ip && sh.ip !== "http://" && !sh.ip.includes("undefined")
    }
  }
}
